
export default {
  props: {
    value: { type: Object, required: true },
    textClass: { type: [String, Object], default: null },
    imgClass: { type: [String, Object], default: null }
  },

  computed: {
    preview() {
      return this?.value?.preview;
    }
  }
};
