
import ProjectModal from '~/components/modals/ProjectModal.vue';

const COMMENT_MAX_LEN = 200;

export default {
  name: 'PageIndex',

  async asyncData({ $content }) {
    const results = await Promise.all([
      $content('index').fetch(),
      $content('about').fetch(),
      $content('faqs').sortBy('order', 'asc').fetch(),
      $content('practice-areas').sortBy('order', 'asc').fetch(),
      $content('projects').sortBy('order', 'asc').fetch(),
      $content('projects-lightbox').sortBy('order', 'asc').fetch(),
      $content('quotes').sortBy('order', 'asc').fetch()
    ]);

    const [
      page,
      about,
      faqs,
      practiceAreas,
      projects,
      projectsLightbox,
      quotes
    ] = results;

    return {
      page,
      about,
      faqs,
      practiceAreas,
      projects,
      projectsLightbox,
      quotes
    };
  },

  data() {
    return {
      form: {
        name: '',
        email: '',
        comment: ''
      },
      quoteSliderIndex: 0,
      COMMENT_MAX_LEN,
      console
    };
  },

  head() {
    return {
      title: this.page.title,
      meta: [
        { property: 'og:title', hid: 'og:title', content: this.page.title }
      ]
    };
  },

  methods: {
    onQuoteSiderAfterChange(i) {
      this.quoteSliderIndex = i;
    },

    showModal(title, description) {
      this.$modal(ProjectModal, this, {
        props: { title, description }
      });
    },

    gotoContact() {
      const route = this.$router.resolve({ path: '/contact' });
      window.open(route.href, '_self');
    }
  }
};
