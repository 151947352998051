
export default {
  name: 'PageIndex',

  props: {
    title: { type: String, required: true },
    description: { type: String, required: true }
  },

  methods: {
    hide() {
      this.$emit('hide', this.$event);
    }
  }
};
