
export default {
  data() {
    return {
      isExpanded: false
    };
  },

  methods: {
    onExpandBtnClick() {
      this.isExpanded = !this.isExpanded;
    }
  }
};
