// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/imgs/hp-banner-mobile.jpg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../assets/imgs/hp-banner-desktop.jpg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "h2[data-v-7cb4a3c2]{text-align:center;font-size:1.875rem;line-height:2.25rem;font-weight:700;--tw-text-opacity:1;color:rgb(33 80 120 / var(--tw-text-opacity))}@media (min-width: 1024px){h2[data-v-7cb4a3c2]{font-size:3rem;line-height:1}}#banner[data-v-7cb4a3c2]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}@media (min-width: 768px){#banner[data-v-7cb4a3c2]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}}#mc_embed_signup[data-v-7cb4a3c2]{background:none;font:inherit}#mc_embed_signup .helper_text[data-v-7cb4a3c2]{background-color:inherit}#mc_embed_signup .button[data-v-7cb4a3c2]{font-weight:600;font-size:1.125rem;line-height:1.75rem;border-radius:.25rem;width:96%;height:auto;padding:.75rem 0;background-color:#040404;color:#d6e558}#mc_embed_signup #mce-success-response[data-v-7cb4a3c2]{color:#040404}#projects[data-v-7cb4a3c2] .grid>*{position:relative;padding-bottom:calc(var(--tw-aspect-h) / var(--tw-aspect-w) * 100%);--tw-aspect-w:13}#projects[data-v-7cb4a3c2] .grid>* > *{position:absolute;height:100%;width:100%;top:0;right:0;bottom:0;left:0}#projects[data-v-7cb4a3c2] .grid>*{--tw-aspect-h:16}@media (min-width: 1024px){#projects[data-v-7cb4a3c2] .grid>*{position:static;padding-bottom:0}#projects[data-v-7cb4a3c2] .grid>* > *{position:static;height:auto;width:auto;top:auto;right:auto;bottom:auto;left:auto}}#work[data-v-7cb4a3c2] .slick-slider .slick-track{display:flex;align-items:center;padding:.75rem 0;border-radius:.25rem}#work[data-v-7cb4a3c2] .slick-slider .slick-slide blockquote p:first-of-type:before,#work[data-v-7cb4a3c2] .slick-slider .slick-slide blockquote p:last-of-type:after{content:none}#work[data-v-7cb4a3c2] .slick-slider .slick-dots{bottom:-3rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
