import { render, staticRenderFns } from "./index.vue?vue&type=template&id=7cb4a3c2&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=7cb4a3c2&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7cb4a3c2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ExpansionItem: require('C:/Users/clong/repo/ml/src/components/ExpansionItem.vue').default,ProjectPreview: require('C:/Users/clong/repo/ml/src/components/ProjectPreview.vue').default})
